import Redirect from '@/components/redirect.vue'
//静态路由
const routes = [
  {
    name: 'Root',
    path: '/',
    component: () => import('@/layout/index.vue'),
    //重定向默认首页
    redirect: '/home',
    children: [
      { name: 'home', path: '/home', component: () => import('@/views/home/index.vue'), meta: { title: '首頁' } },
      //個人中心
      {
        path: '/my',
        component: () => import('@/views/my/index.vue'),
        meta: { title: '個人中心' },
      },
      //收款人管理
      {
        path: '/payee',
        redirect: Redirect,
        children: [
          {
            name: 'payee',
            path: '',
            component: () => import('@/views/my/payee/payee.vue'),
            meta: { title: '收款人管理' },
          },
          {
            name: 'payee_add',
            path: 'add',
            component: () => import('@/views/my/payee/add.vue'),
            meta: { title: '添加收款人' },
          },

        ]
      },
      //訂單記錄
      {
        path: '/order',
        redirect: Redirect,
        children: [
          {
            name: 'order',
            path: '',
            component: () => import('@/views/order/index.vue'),
            meta: { title: '訂單記錄' },
          },
          {
            name: 'order_detail',
            path: 'detail',
            component: () => import('@/views/order/detail.vue'),
            meta: { title: '訂單詳情' },
          },

        ]
      },
    ]
  },
  //登入頁
  {
    name: 'login',
    path: '/login',
    component: () => import('@/views/login/index.vue'),
    meta: { title: '登入' }
  },
  //註冊
  {
    name: 'register',
    path: '/register',
    component: () => import('@/views/register/index.vue'),
    meta: { title: '註冊' }
  },
  //匯款預填
  {
    path: '/prefill',
    redirect: Redirect,
    children: [
      {
        name: 'prefill',
        path: '',
        component: () => import('@/views/prefill/index.vue'),
        meta: { title: '匯款預填' },
      },
      {
        name: 'personage',
        path: 'personage',
        component: () => import('@/views/prefill/personage/personage.vue'),
        meta: { title: '填寫個人資料' }
      },
      {
        name: 'firm',
        path: 'firm',
        component: () => import('@/views/prefill/firm/firm.vue'),
        meta: { title: '填寫企業資料' }
      },
      {
        name: 'prefill_form',
        path: 'prefill',
        component: () => import('@/views/prefill/prefill/prefill.vue'),
        meta: { title: '匯款預填' }
      },
    ]
  },
 

  {
    name: 'error',
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/error/index.vue'),
    meta: { title: '404' }
  }

]

export default routes