export default {
	// src\layout\index.vue
	"首頁":"首页",
	"匯款預填":"汇款预填",
	"訂單記錄":"订单记录",
	"個人中心":"个人中心",

	// src\views\home\index.vue
	"匯率":"汇率",
	"汇款":"汇款",
	"没有更多了":"没有更多了",

	// src\views\login\index.vue
	"賬號":"账号",
	"請輸入賬號":"请输入账号",
	"賬號不能為空":"账号不能为空",
	"密碼":"密码",
	"請輸入密碼":"请输入密码",
	"密碼不能為空":"密码不能为空",
	"已閱讀並同意":"已阅读并同意",
	"xxxx協議":"xxxx协议",
	"註冊賬號":"注册帐号",
	"忘記密碼":"忘记密码",

	// src\views\my\index.vue
	"個人收款賬戶":"个人收款账户",
	"編輯":"编辑",
	"名稱":"名称",
	"英文名稱":"英文名称",
	"身份證或護照":"身份证或护照",
	"國籍/地區":"国籍/地区",
	"性別":"性别",
	"企業收款賬戶":"企业收款账户",
	"企業名稱":"企业名称",
	"商業登記證號碼":"商业登记证号码",
	"註冊地址":"注册地址",
	"收款人管理":"收款人管理",
	"錢包":"钱包",
	"當前匯率":"当前汇率",
	"隱私條款":"隐私条款",
	"版本信息":"版本信息",
	"聯絡我們":"联络我们",

	// src\views\my\payee\add.vue
	"添加收款人":"添加收款人",
	"返回":"返回",
	"證照類型":"证照类型",
	"請選擇證照類型":"请选择证照类型",
	"證照識別":"证照识别",
	"收款人名稱":"收款人名称",
	"請填寫收款人名稱":"请填写收款人名称",
	"收款人名稱不能為空":"收款人名称不能为空",
	"請填寫身份證或護照":"请填写身份证或护照",
	"身份證或護照不能為空":"身份证或护照不能为空",
	"請選擇國籍/地區":"请选择国籍/地区",
	"國籍/地區不能為空":"国籍/地区不能为空",
	"選擇國籍/地區":"选择国籍/地区",
	"與匯款人的關係":"与汇款人的关系",
	"請選擇與匯款人的關係":"请选择与汇款人的关系",
	"與匯款人的關係不能為空":"与汇款人的关系不能为空",
	"收款人地址":"收款人地址",
	"請填寫收款人地址":"请填写收款人地址",
	"收款人電話":"收款人电话",
	"請填寫收款人電話":"请填写收款人电话",
	"收款人賬號":"收款人账号",
	"請填寫收款人賬號":"请填写收款人账号",
	"收款人賬號不能為空":"收款人账号不能为空",
	"銀行名稱":"银行名称",
	"請選擇銀行名稱":"请选择银行名称",
	"銀行名稱不能為空":"银行名称不能为空",
	"會員文件":"会员文件",
	"資料說明":"数据说明",
	"請填寫資料說明":"请填写数据说明",
	"保存":"保存",

	// src\views\my\payee\payee.vue
	"銀行賬戶":"银行账户",
	"刪除":"删除",

	// src\views\order\detail.vue
	"基本資料":"基本资料",
	"匯款代幣":"汇款代币",
	"兌換代幣":"兑换代币",
	"手續費":"手续费",
	"可到店辦理業務":"可到店办理业务",
	"點擊查看門店位置":"点击查看门店位置",
	"訂單編號":"订单编号",
	"訂單狀態":"订单状态",
	"提交日期":"提交日期",
	"完成日期":"完成日期",
	"訂單備註":"订单备注",
	"收款人資料":"收款人数据",
	"與匯款人關係":"与汇款人关系",
	"收款人文件":"收款人文件",
	"其他資料":"其他数据",
	"資金來源":"资金来源",
	"兌換用途":"兑换用途",
	"匯款人職業":"汇款人职业",
	"每月收入":"每月收入",
	"收款人收款地":"收款人收款地",
	"使用匯款服務原因":"使用汇款服务原因",

	// src\views\order\index.vue
	"收款人/銀行賬戶/訂單編號":"收款人/银行账户/订单编号",
	"全部訂單":"全部订单",
	"審批日期":"审批日期",
	"已提交訂單":"已提交订单",
	"需補資料訂單":"需补数据订单",
	"預填完成":"预填完成",
	"匯款完成":"汇款完成",
	"已失效":"已失效",
	"補充資料說明":"补充数据说明",
	"去補齊":"去补齐",
	"暫時不":"暂时不",
	"補充資料": "补充材料",
	"需補資料": "需补材料",
	"審批中": "审批中",
	"預填成功": "预填成功",

	// src\views\prefill\index.vue
	"您預填的類型是": "您预填的类型是",
	"企業": "企业",
	"個人": "个人",

	// src\views\prefill\components\setp1.vue
	"公司名稱":"公司名称",
	"請填寫公司名稱":"请填写公司名称",
	"公司名稱不能為空":"公司名称不能为空",
	"公司英文名稱":"公司英文名称",
	"請填寫公司英文名稱":"请填写公司英文名称",
	"請填寫商業登記證號碼":"请填写商业登记证号码",
	"商業登記證號碼不能為空":"商业登记证号码不能为空",
	"證件有效期":"证件有效期",
	"請填寫證件有效期":"请填写证件有效期",
	"證件有效期不能為空":"证件有效期不能为空",
	"選擇日期":"选择日期",
	"公司註冊地":"公司注册地",
	"請選擇公司註冊地":"请选择公司注册地",
	"公司註冊地不能為空":"公司注册地不能为空",
	"業務性質":"业务性质",
	"請填寫業務性質":"请填写业务性质",
	"業務性質不能為空":"业务性质不能为空",
	"公司電話號碼":"公司电话号码",
	"請填寫公司電話號碼":"请填写公司电话号码",
	"公司電話號碼不能為空":"公司电话号码不能为空",
	"公司電郵":"公司电邮",
	"請填寫公司電郵":"请填写公司电邮",
	"公司電郵不能為空":"公司电邮不能为空",
	"Whatsapp":"Whatsapp",
	"請填寫Whatsapp":"请填写Whatsapp",
	"Whatsapp不能為空":"Whatsapp不能为空",

	// src\views\prefill\components\setp2.vue
	"請填寫名稱":"请填写名称",
	"名稱不能為空":"名称不能为空",
	"出生日期":"出生日期",
	"請填寫出生日期":"请填写出生日期",
	"出生日期不能為空":"出生日期不能为空",
	"男":"男",
	"女":"女",
	"職位":"职位",
	"請選擇職位":"请选择职位",
	"刪除成員":"删除成员",
	"添加成員":"添加成员",

	// src\views\prefill\components\setp3.vue

	// src\views\prefill\firm\firm.vue
	"填寫企業資料":"填写企业资料",
	"成員資料":"成员数据",
	"補充文件":"补充文件",
	"保存並提交":"保存并提交",
	"保存並下一步":"保存并下一步",

	// src\views\prefill\firm\setp1.vue

	// src\views\prefill\firm\setp2.vue
	"性別不能為空":"性别不能为空",

	// src\views\prefill\firm\setp3.vue

	// src\views\prefill\personage\personage.vue
	"填寫個人資料":"填写个人资料",
	"電話號碼":"电话号码",
	"請填寫電話號碼":"请填写电话号码",
	"電郵":"电邮",
	"請填寫電郵":"请填写电邮",
	"住址":"住址",
	"請填寫住址":"请填写住址",
	"職業":"职业",
	"請填寫職業":"请填写职业",
	"每月收入(港幣)":"每月收入（港币）",
	"請填寫每月收入":"请填写每月收入",

	// src\views\prefill\prefill\prefill.vue
	"業務資料":"业务数据",
	"匯款資料":"汇款数据",
	"完成":"完成",
	"再來一單":"再来一单",
	"返回首頁":"返回首页",
	"下一步":"下一步",

	// src\views\prefill\prefill\setp1.vue
	"匯款人資料":"汇款人数据",
	"註冊地":"注册地",
	"修改資料":"修改资料",

	// src\views\prefill\prefill\setp2.vue
	"匯款方式":"汇款方式",
	"請選擇匯款方式":"请选择汇款方式",
	"從收款人記錄中匯入":"从收款人记录中汇入",

	// src\views\prefill\prefill\setp3.vue
	"請選擇資金來源":"请选择资金来源",
	"資金來源不能為空":"资金来源不能为空",
	"請選擇兌換用途":"请选择兑换用途",
	"兌換用途不能為空":"兑换用途不能为空",
	"請選擇匯款人職業":"请选择汇款人职业",
	"請選擇每月收入":"请选择每月收入",
	"請選擇收款人收款地":"请选择收款人收款地",
	"請填寫使用匯款服務原因":"请填写使用汇款服务原因",

	// src\views\prefill\prefill\setp4.vue

	// src\views\register\index.vue
	"註冊":"注册",
	"登入郵箱":"登入邮箱",
	"請輸入登入郵箱":"请输入登入邮箱",
	"登入密碼":"登入密码",
	"請輸入登入密碼":"请输入登入密码",
	"登入密碼不能為空":"登入密码不能为空",
	"確認密碼":"确认密码",
	"請輸入確認密碼":"请输入确认密码",
	"確認密碼不能為空":"确认密码不能为空",
	"驗證碼":"验证码",
	"請輸入驗證碼":"请输入验证码",
	"驗證碼不能為空":"验证码不能为空",
	"註冊並登入":"注册并登入",

}