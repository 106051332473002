import { createRouter, createWebHashHistory } from 'vue-router' 
import routes from './routes'
//导入进度条


const router = createRouter({
  history: createWebHashHistory(),
  routes: routes
})





//导航前置守卫
router.beforeEach(async (to, from, next) => {
  next()
})


//导航后置守卫
router.afterEach((to, from) => {
  //关闭进度条
})



export default router