import { createI18n } from "vue-i18n";

import zhTW from './lang/zh-TW'
import zhCN from './lang/zh-CN'
import enUS from './lang/en-US'

const messages={
  'zh-TW':{
    ...zhTW,
  },
  'zh-CN':{
    ...zhCN,
  },
  'en':{
    ...enUS,
  }
}



let lang = sessionStorage.getItem('lang')
 const i18n=createI18n({
   locale: lang ? lang : 'zh-TW',
  legacy:false,
  messages
 })



 export default i18n


