import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'

import i18n from '@/i18n'
import vant from 'vant';
import 'vant/lib/index.css';
import '@/styles/index.css'
import '@/styles/iconfont/iconfont.css'



const app = createApp(App)
app.use(router)
app.use(vant)
app.use(i18n)



app.mount('#app')