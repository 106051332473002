export default {
	// src\layout\index.vue
	"首頁":"Home",
	"匯款預填":"Prefill",
	"訂單記錄":"Order",
	"個人中心":"Personal Center",

	// src\views\home\index.vue
	"匯率":"exchange rate",
	"汇款":"remittance",
	"没有更多了":"There's nothing more left",

	// src\views\login\index.vue
	"賬號":"Account",
	"請輸入賬號":"Please enter your account number",
	"賬號不能為空":"Account cannot be empty",
	"密碼":"password",
	"請輸入密碼":"Please enter password",
	"密碼不能為空":"Password cannot be empty",
	"已閱讀並同意":"Read and agree",
	"xxxx協議":"Xxxx protocol",
	"註冊賬號":"Register an account",
	"忘記密碼":"Forgot password",

	// src\views\my\index.vue
	"個人收款賬戶":"Personal receiving account",
	"編輯":"edit",
	"名稱":"name",
	"英文名稱":"English name",
	"身份證或護照":"ID card or passport",
	"國籍/地區":"Nationality/Region",
	"性別":"Gender",
	"企業收款賬戶":"Enterprise receiving account",
	"企業名稱":"Enterprise Name",
	"商業登記證號碼":"Business registration certificate number",
	"註冊地址":"Company Registered Address",
	"收款人管理":"Payee Management",
	"錢包":"wallet",
	"當前匯率":"Current exchange rate",
	"隱私條款":"Privacy Clause",
	"版本信息":"Version information",
	"聯絡我們":"Contact us",

	// src\views\my\payee\add.vue
	"添加收款人":"Add payee",
	"返回":"return",
	"證照類型":"License type",
	"請選擇證照類型":"Please select the type of license",
	"證照識別":"License recognition",
	"收款人名稱":"Name of payee",
	"請填寫收款人名稱":"Please fill in the name of the payee",
	"收款人名稱不能為空":"The payee name cannot be empty",
	"請填寫身份證或護照":"Please fill in your ID card or passport",
	"身份證或護照不能為空":"ID card or passport cannot be empty",
	"請選擇國籍/地區":"Please select nationality/region",
	"國籍/地區不能為空":"Nationality/region cannot be empty",
	"選擇國籍/地區":"Choose nationality/region",
	"與匯款人的關係":"Relationship with remitter",
	"請選擇與匯款人的關係":"Please select the relationship with the remitter",
	"與匯款人的關係不能為空":"The relationship with the remitter cannot be empty",
	"收款人地址":"Receiver Address ",
	"請填寫收款人地址":"Please fill in the recipient's address",
	"收款人電話":"Payee's phone number",
	"請填寫收款人電話":"Please fill in the recipient's phone number",
	"收款人賬號":"beneficiary account no ",
	"請填寫收款人賬號":"Please fill in the recipient's account number",
	"收款人賬號不能為空":"The recipient's account cannot be empty",
	"銀行名稱":"Bank name",
	"請選擇銀行名稱":"Please select the bank name",
	"銀行名稱不能為空":"Bank name cannot be empty",
	"會員文件":"Membership documents",
	"資料說明":"Data Description",
	"請填寫資料說明":"Please fill in the data description",
	"保存":"preserve",

	// src\views\my\payee\payee.vue
	"銀行賬戶":"bank account",
	"刪除":"Delete",

	// src\views\order\detail.vue
	"基本資料":"Basic information",
	"匯款代幣":"Remittance tokens",
	"兌換代幣":"Exchange tokens",
	"手續費":"Handling fees",
	"可到店辦理業務":"Can handle business at the store",
	"點擊查看門店位置":"Click to view store location",
	"訂單編號":"Order number",
	"訂單狀態":"Order status",
	"提交日期":"Submission date",
	"完成日期":"Completion date",
	"訂單備註":"Order remarks",
	"收款人資料":"Payee data",
	"與匯款人關係":"Relationship with remitter",
	"收款人文件":"Payee file",
	"其他資料":"Other data",
	"資金來源":"Funding source",
	"兌換用途":"Exchange purpose",
	"匯款人職業":"Occupation of remitter",
	"每月收入":"Monthly income",
	"收款人收款地":"Payee's place of payment",
	"使用匯款服務原因":"Reasons for using remittance services",

	// src\views\order\index.vue
	"收款人/銀行賬戶/訂單編號":"Payee/Bank Account/Order Number",
	"全部訂單":"All orders",
	"審批日期":"Approval date",
	"已提交訂單":"Order submitted",
	"需補資料訂單":"Data order needs to be supplemented",
	"預填完成":"Pre filling completed",
	"匯款完成":"Remittance completed",
	"已失效":"Invalid",
	"補充資料說明":"Supplementary data explanation",
	"去補齊":"Go and make up for it",
	"暫時不":"Not yet",
	"補充資料": "Supplementary Information",
	"需補資料": "Data required",
	"審批中": "Approval ",
	"預填成功": "Prefill successful",

	// src\views\prefill\index.vue
	"您預填的類型是": "The type you prefilled is",
	"企業": "Enterprise",
	"個人": "Individual",

	// src\views\prefill\components\setp1.vue
	"公司名稱":"Company Name",
	"請填寫公司名稱":"Please fill in the company name",
	"公司名稱不能為空":"Company name cannot be empty",
	"公司英文名稱":"Company English Name",
	"請填寫公司英文名稱":"Please fill in the English name of the company",
	"請填寫商業登記證號碼":"Please fill in the business registration certificate number",
	"商業登記證號碼不能為空":"The business registration certificate number cannot be empty",
	"證件有效期":"Validity period of documents",
	"請填寫證件有效期":"Please fill in the validity period of the document",
	"證件有效期不能為空":"The validity period of the certificate cannot be empty",
	"選擇日期":"Select date",
	"公司註冊地":"Company registration location",
	"請選擇公司註冊地":"Please select the place of company registration",
	"公司註冊地不能為空":"Company registration location cannot be empty",
	"業務性質":"Business nature",
	"請填寫業務性質":"Please fill in the nature of the business",
	"業務性質不能為空":"Business nature cannot be empty",
	"公司電話號碼":"Company phone number",
	"請填寫公司電話號碼":"Please fill in the company phone number",
	"公司電話號碼不能為空":"Company phone number cannot be empty",
	"公司電郵":"Company Email ",
	"請填寫公司電郵":"Please fill in the company email",
	"公司電郵不能為空":"Company email cannot be empty",
	"Whatsapp":"Whatsapp",
	"請填寫Whatsapp":"Please fill in Whatsapp",
	"Whatsapp不能為空":"Whatsapp cannot be empty",

	// src\views\prefill\components\setp2.vue
	"請填寫名稱":"Please fill in the name",
	"名稱不能為空":"Name cannot be empty",
	"出生日期":"Date of Birth:",
	"請填寫出生日期":"Please fill in the date of birth",
	"出生日期不能為空":"Birth date cannot be empty",
	"男":"male",
	"女":"female",
	"職位":"position",
	"請選擇職位":"Please select a position",
	"刪除成員":"Delete member",
	"添加成員":"Add members",

	// src\views\prefill\components\setp3.vue

	// src\views\prefill\firm\firm.vue
	"填寫企業資料":"Fill in enterprise information",
	"成員資料":"Member data",
	"補充文件":"Supplementary documents",
	"保存並提交":"Save and submit",
	"保存並下一步":"Save and Next",

	// src\views\prefill\firm\setp1.vue

	// src\views\prefill\firm\setp2.vue
	"性別不能為空":"Gender cannot be empty",

	// src\views\prefill\firm\setp3.vue

	// src\views\prefill\personage\personage.vue
	"填寫個人資料":"Fill in personal information",
	"電話號碼":"Phone number:",
	"請填寫電話號碼":"Please fill in the phone number",
	"電郵":"Email",
	"請填寫電郵":"Please fill in the email address",
	"住址":"address",
	"請填寫住址":"Please provide your address",
	"職業":"occupation",
	"請填寫職業":"Please fill in your occupation",
	"每月收入(港幣)":"Monthly income (in Hong Kong dollars)",
	"請填寫每月收入":"Please fill in monthly income",

	// src\views\prefill\prefill\prefill.vue
	"業務資料":"Business data",
	"匯款資料":"Remittance data",
	"完成":"complete",
	"再來一單":"Another order",
	"返回首頁":"Return to homepage",
	"下一步":"Next",

	// src\views\prefill\prefill\setp1.vue
	"匯款人資料":"Remitter data",
	"註冊地":"Registration location",
	"修改資料":"Modifying Information",

	// src\views\prefill\prefill\setp2.vue
	"匯款方式":"Remittance method",
	"請選擇匯款方式":"Please select the remittance method",
	"從收款人記錄中匯入":"Remittance from payee records",

	// src\views\prefill\prefill\setp3.vue
	"請選擇資金來源":"Please select the source of funds",
	"資金來源不能為空":"The source of funds cannot be empty",
	"請選擇兌換用途":"Please select the purpose of redemption",
	"兌換用途不能為空":"The exchange purpose cannot be empty",
	"請選擇匯款人職業":"Please select the remitter's occupation",
	"請選擇每月收入":"Please select monthly income",
	"請選擇收款人收款地":"Please select the recipient's place of payment",
	"請填寫使用匯款服務原因":"Please fill in the reason for using the remittance service",

	// src\views\prefill\prefill\setp4.vue

	// src\views\register\index.vue
	"註冊":"register",
	"登入郵箱":"Log in to email",
	"請輸入登入郵箱":"Please enter the login email",
	"登入密碼":"login password ",
	"請輸入登入密碼":"Please enter the login password",
	"登入密碼不能為空":"Login password cannot be empty",
	"確認密碼":"Confirm password",
	"請輸入確認密碼":"Please enter a confirmation password",
	"確認密碼不能為空":"Confirm password cannot be empty",
	"驗證碼":"Verification code",
	"請輸入驗證碼":"Please enter the verification code",
	"驗證碼不能為空":"The verification code cannot be empty",
	"註冊並登入":"Register and log in",

}