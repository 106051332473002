export default {
	// src\layout\index.vue
	"首頁":"首頁",
	"匯款預填":"匯款預填",
	"訂單記錄":"訂單記錄",
	"個人中心":"個人中心",

	// src\views\home\index.vue
	"匯率":"匯率",
	"汇款":"汇款",
	"没有更多了":"没有更多了",

	// src\views\login\index.vue
	"賬號":"賬號",
	"請輸入賬號":"請輸入賬號",
	"賬號不能為空":"賬號不能為空",
	"密碼":"密碼",
	"請輸入密碼":"請輸入密碼",
	"密碼不能為空":"密碼不能為空",
	"已閱讀並同意":"已閱讀並同意",
	"xxxx協議":"xxxx協議",
	"註冊賬號":"註冊賬號",
	"忘記密碼":"忘記密碼",

	// src\views\my\index.vue
	"個人收款賬戶":"個人收款賬戶",
	"編輯":"編輯",
	"名稱":"名稱",
	"英文名稱":"英文名稱",
	"身份證或護照":"身份證或護照",
	"國籍/地區":"國籍/地區",
	"性別":"性別",
	"企業收款賬戶":"企業收款賬戶",
	"企業名稱":"企業名稱",
	"商業登記證號碼":"商業登記證號碼",
	"註冊地址":"註冊地址",
	"收款人管理":"收款人管理",
	"錢包":"錢包",
	"當前匯率":"當前匯率",
	"隱私條款":"隱私條款",
	"版本信息":"版本信息",
	"聯絡我們":"聯絡我們",

	// src\views\my\payee\add.vue
	"添加收款人":"添加收款人",
	"返回":"返回",
	"證照類型":"證照類型",
	"請選擇證照類型":"請選擇證照類型",
	"證照識別":"證照識別",
	"收款人名稱":"收款人名稱",
	"請填寫收款人名稱":"請填寫收款人名稱",
	"收款人名稱不能為空":"收款人名稱不能為空",
	"請填寫身份證或護照":"請填寫身份證或護照",
	"身份證或護照不能為空":"身份證或護照不能為空",
	"請選擇國籍/地區":"請選擇國籍/地區",
	"國籍/地區不能為空":"國籍/地區不能為空",
	"選擇國籍/地區":"選擇國籍/地區",
	"與匯款人的關係":"與匯款人的關係",
	"請選擇與匯款人的關係":"請選擇與匯款人的關係",
	"與匯款人的關係不能為空":"與匯款人的關係不能為空",
	"收款人地址":"收款人地址",
	"請填寫收款人地址":"請填寫收款人地址",
	"收款人電話":"收款人電話",
	"請填寫收款人電話":"請填寫收款人電話",
	"收款人賬號":"收款人賬號",
	"請填寫收款人賬號":"請填寫收款人賬號",
	"收款人賬號不能為空":"收款人賬號不能為空",
	"銀行名稱":"銀行名稱",
	"請選擇銀行名稱":"請選擇銀行名稱",
	"銀行名稱不能為空":"銀行名稱不能為空",
	"會員文件":"會員文件",
	"資料說明":"資料說明",
	"請填寫資料說明":"請填寫資料說明",
	"保存":"保存",

	// src\views\my\payee\payee.vue
	"您預填的類型是":"您預填的類型是",
	"刪除":"刪除",

	

	// src\views\order\detail.vue
	"基本資料":"基本資料",
	"匯款代幣":"匯款代幣",
	"兌換代幣":"兌換代幣",
	"手續費":"手續費",
	"可到店辦理業務":"可到店辦理業務",
	"點擊查看門店位置":"點擊查看門店位置",
	"訂單編號":"訂單編號",
	"訂單狀態":"訂單狀態",
	"提交日期":"提交日期",
	"完成日期":"完成日期",
	"訂單備註":"訂單備註",
	"收款人資料":"收款人資料",
	"與匯款人關係":"與匯款人關係",
	"收款人文件":"收款人文件",
	"其他資料":"其他資料",
	"資金來源":"資金來源",
	"兌換用途":"兌換用途",
	"匯款人職業":"匯款人職業",
	"每月收入":"每月收入",
	"收款人收款地":"收款人收款地",
	"使用匯款服務原因":"使用匯款服務原因",

	// src\views\order\index.vue
	"收款人/銀行賬戶/訂單編號":"收款人/銀行賬戶/訂單編號",
	"全部訂單":"全部訂單",
	"審批日期":"審批日期",
	"已提交訂單":"已提交訂單",
	"需補資料訂單":"需補資料訂單",
	"預填完成":"預填完成",
	"匯款完成":"匯款完成",
	"已失效":"已失效",
	"補充資料說明":"補充資料說明",
	"去補齊":"去補齊",
	"暫時不":"暫時不",
	"補充資料":"補充資料",
	"需補資料":"需補資料",
	"審批中":"審批中",
	"預填成功":"預填成功",

	// src\views\prefill\index.vue
	"您預填的類型是": "您預填的類型是",
	"企業": "企業",
	"個人": "個人",

	// src\views\prefill\components\setp1.vue
	"公司名稱":"公司名稱",
	"請填寫公司名稱":"請填寫公司名稱",
	"公司名稱不能為空":"公司名稱不能為空",
	"公司英文名稱":"公司英文名稱",
	"請填寫公司英文名稱":"請填寫公司英文名稱",
	"請填寫商業登記證號碼":"請填寫商業登記證號碼",
	"商業登記證號碼不能為空":"商業登記證號碼不能為空",
	"證件有效期":"證件有效期",
	"請填寫證件有效期":"請填寫證件有效期",
	"證件有效期不能為空":"證件有效期不能為空",
	"選擇日期":"選擇日期",
	"公司註冊地":"公司註冊地",
	"請選擇公司註冊地":"請選擇公司註冊地",
	"公司註冊地不能為空":"公司註冊地不能為空",
	"業務性質":"業務性質",
	"請填寫業務性質":"請填寫業務性質",
	"業務性質不能為空":"業務性質不能為空",
	"公司電話號碼":"公司電話號碼",
	"請填寫公司電話號碼":"請填寫公司電話號碼",
	"公司電話號碼不能為空":"公司電話號碼不能為空",
	"公司電郵":"公司電郵",
	"請填寫公司電郵":"請填寫公司電郵",
	"公司電郵不能為空":"公司電郵不能為空",
	"Whatsapp":"Whatsapp",
	"請填寫Whatsapp":"請填寫Whatsapp",
	"Whatsapp不能為空":"Whatsapp不能為空",

	// src\views\prefill\components\setp2.vue
	"請填寫名稱":"請填寫名稱",
	"名稱不能為空":"名稱不能為空",
	"出生日期":"出生日期",
	"請填寫出生日期":"請填寫出生日期",
	"出生日期不能為空":"出生日期不能為空",
	"男":"男",
	"女":"女",
	"職位":"職位",
	"請選擇職位":"請選擇職位",
	"刪除成員":"刪除成員",
	"添加成員":"添加成員",

	// src\views\prefill\components\setp3.vue

	// src\views\prefill\firm\firm.vue
	"填寫企業資料":"填寫企業資料",
	"成員資料":"成員資料",
	"補充文件":"補充文件",
	"保存並提交":"保存並提交",
	"保存並下一步":"保存並下一步",

	// src\views\prefill\firm\setp1.vue

	// src\views\prefill\firm\setp2.vue
	"性別不能為空":"性別不能為空",

	// src\views\prefill\firm\setp3.vue

	// src\views\prefill\personage\personage.vue
	"填寫個人資料":"填寫個人資料",
	"電話號碼":"電話號碼",
	"請填寫電話號碼":"請填寫電話號碼",
	"電郵":"電郵",
	"請填寫電郵":"請填寫電郵",
	"住址":"住址",
	"請填寫住址":"請填寫住址",
	"職業":"職業",
	"請填寫職業":"請填寫職業",
	"每月收入(港幣)":"每月收入(港幣)",
	"請填寫每月收入":"請填寫每月收入",

	// src\views\prefill\prefill\prefill.vue
	"業務資料":"業務資料",
	"匯款資料":"匯款資料",
	"完成":"完成",
	"再來一單":"再來一單",
	"返回首頁":"返回首頁",
	"下一步":"下一步",

	// src\views\prefill\prefill\setp1.vue
	"匯款人資料":"匯款人資料",
	"註冊地":"註冊地",
	"修改資料":"修改資料",

	// src\views\prefill\prefill\setp2.vue
	"匯款方式":"匯款方式",
	"請選擇匯款方式":"請選擇匯款方式",
	"從收款人記錄中匯入":"從收款人記錄中匯入",

	// src\views\prefill\prefill\setp3.vue
	"請選擇資金來源":"請選擇資金來源",
	"資金來源不能為空":"資金來源不能為空",
	"請選擇兌換用途":"請選擇兌換用途",
	"兌換用途不能為空":"兌換用途不能為空",
	"請選擇匯款人職業":"請選擇匯款人職業",
	"請選擇每月收入":"請選擇每月收入",
	"請選擇收款人收款地":"請選擇收款人收款地",
	"請填寫使用匯款服務原因":"請填寫使用匯款服務原因",

	// src\views\prefill\prefill\setp4.vue

	// src\views\register\index.vue
	"註冊":"註冊",
	"登入郵箱":"登入郵箱",
	"請輸入登入郵箱":"請輸入登入郵箱",
	"登入密碼":"登入密碼",
	"請輸入登入密碼":"請輸入登入密碼",
	"登入密碼不能為空":"登入密碼不能為空",
	"確認密碼":"確認密碼",
	"請輸入確認密碼":"請輸入確認密碼",
	"確認密碼不能為空":"確認密碼不能為空",
	"驗證碼":"驗證碼",
	"請輸入驗證碼":"請輸入驗證碼",
	"驗證碼不能為空":"驗證碼不能為空",
	"註冊並登入":"註冊並登入",

}